<template>
    <div class="table-view user-group-table-view">
        <div class="action-header">
            <h1>{{ groupName }}</h1>
            <div class="actions">
                <button type="button" class="btn btn-grey" @click="showAddComponent=true">
                    {{ $t('Add') }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.285" height="18.285" viewBox="0 0 18.285 18.285">
                        <g>
                            <g>
                                <path d="M8.148 2.5H4.87A2.37 2.37 0 0 0 2.5 4.87v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37V4.87a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339H4.87a.339.339 0 0 1-.339-.339V4.87a.339.339 0 0 1 .339-.339h3.278a.339.339 0 0 1 .339.339zM18.415 2.5h-3.278a2.37 2.37 0 0 0-2.37 2.37v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37V4.87a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339h-3.278a.339.339 0 0 1-.339-.339V4.87a.339.339 0 0 1 .339-.339h3.278a.339.339 0 0 1 .339.339zM8.148 12.767H4.87a2.37 2.37 0 0 0-2.37 2.37v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37v-3.278a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339H4.87a.339.339 0 0 1-.339-.339v-3.278a.339.339 0 0 1 .339-.337h3.278a.339.339 0 0 1 .339.339zm12.3-1.639a1.016 1.016 0 0 1-1.016 1.016h-1.979v1.978a1.016 1.016 0 1 1-2.032 0v-1.978h-1.977a1.016 1.016 0 1 1 0-2.032h1.977v-1.977a1.016 1.016 0 1 1 2.032 0v1.977h1.978a1.016 1.016 0 0 1 1.015 1.016z" transform="translate(-2.5 -2.5) translate(2.5 2.5) translate(-2.5 -2.5)"/>
                            </g>
                        </g>
                    </svg>
                </button>
                <button type="button" class="btn btn-grey" @click="getPermissions">
                    {{ $t('Reload') }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.948" height="20" viewBox="0 0 20.948 20">
                        <g>
                            <g>
                                <path d="M10 13a8.37 8.37 0 0 1 7.528 4.47 3 3 0 0 1-2.58 4.53h-9.9a3 3 0 0 1-2.581-4.53A8.37 8.37 0 0 1 10 13zm0 2a6.383 6.383 0 0 0-5.808 3.49 1 1 0 0 0 .86 1.51h9.9a1 1 0 0 0 .86-1.51A6.383 6.383 0 0 0 10 15zm9-7a1 1 0 0 1 .993.883L20 9v2h2a1 1 0 0 1 .117 1.993L22 13h-2v2a1 1 0 0 1-1.993.117L18 15v-2h-2a1 1 0 0 1-.117-1.993L16 11h2V9a1 1 0 0 1 1-1zm-8.833-6a5 5 0 0 1 0 10H10a5 5 0 0 1 0-10h.167zm-.083 2h.083a3 3 0 0 0-.083 6H10a3 3 0 0 0 .083-6z" transform="translate(-820.052 268) translate(818 -270)"/>
                            </g>
                        </g>
                    </svg>
                </button>
                <button type="button" class="btn btn-grey" @click="deleteUserGroup">
                    {{ $t('Delete Group') }}
                    <svg class="viewable" xmlns="http://www.w3.org/2000/svg" width="15" height="17.764" viewBox="0 0 15 17.764" @click="deleteUser(user)">
                        <g>
                            <path d="M8.591 17.728a2.419 2.419 0 0 0 2.419 2.317h7.38a2.419 2.419 0 0 0 2.419-2.317l.475-10.939H8.116zm7.9-8.6a.605.605 0 0 1 1.21.039l-.279 7.933a.6.6 0 0 1-.6.584.6.6 0 0 1-.6-.626zm-4.207-.581a.6.6 0 0 1 .626.587l.281 7.926a.6.6 0 0 1-.6.626.6.6 0 0 1-.6-.584l-.266-7.935a.6.6 0 0 1 .569-.62zM21.6 4.37h-4.036V2.885a.6.6 0 0 0-.6-.6h-4.523a.6.6 0 0 0-.6.6V4.37H7.8a.6.6 0 0 0 0 1.21h13.8a.6.6 0 1 0 0-1.21zm-8.549-.88h3.308v.88h-3.313z" transform="translate(-7.2 -2.28)"/>
                        </g>
                    </svg>
                </button>
            </div>
        </div>

        <table class="table permision-table">
            <thead>
                <tr>
                    <th scope="col" class="title-col">{{ $t('Title') }}</th>
                    <th scope="col">{{ $t('View') }}</th>
                    <th scope="col">{{ $t('Create') }}</th>
                    <th scope="col">{{ $t('Update') }}</th>
                    <th scope="col">{{ $t('Delete') }}</th>
                    <th scope="col">{{ $t('Export') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="userPermissionTitle in permissionTitles?.main" :key="userPermissionTitle.id">
                    <td class="title-col">{{ userPermissionTitle.name }}</td>
                    <td>
                        <div class="form-check centered-checkbox">
                            <input class="form-check-input" v-model="selectedPermissions" type="checkbox" aria-label="Checkbox for View"
                                :value="`can_view_${snakeCase(userPermissionTitle.name)}`"
                                :disabled="!maxPermissions?.includes(`can_view_${snakeCase(userPermissionTitle.name)}`)">
                        </div>
                    </td>
                    <td>
                        <div class="form-check centered-checkbox">
                            <input class="form-check-input" v-model="selectedPermissions" type="checkbox" aria-label="Checkbox for Create"
                                :value="`can_create_${snakeCase(userPermissionTitle.name)}`"
                                :disabled="!maxPermissions?.includes(`can_create_${snakeCase(userPermissionTitle.name)}`)">
                        </div>
                    </td>
                    <td>
                        <div class="form-check centered-checkbox">
                            <input class="form-check-input" v-model="selectedPermissions" type="checkbox" aria-label="Checkbox for Update"
                                :value="`can_update_${snakeCase(userPermissionTitle.name)}`"
                                :disabled="!maxPermissions?.includes(`can_update_${snakeCase(userPermissionTitle.name)}`)">
                        </div>
                    </td>
                    <td>
                        <div class="form-check centered-checkbox">
                            <input class="form-check-input" v-model="selectedPermissions" type="checkbox" aria-label="Checkbox for Delete"
                                :value="`can_delete_${snakeCase(userPermissionTitle.name)}`"
                                :disabled="!maxPermissions?.includes(`can_delete_${snakeCase(userPermissionTitle.name)}`)">
                        </div>
                    </td>
                    <td>
                        <div class="form-check centered-checkbox">
                            <input class="form-check-input" v-model="selectedPermissions" type="checkbox" aria-label="Checkbox for Export"
                                :value="`can_export_${snakeCase(userPermissionTitle.name)}`"
                                :disabled="!maxPermissions?.includes(`can_export_${snakeCase(userPermissionTitle.name)}`)">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <table class="table permision-table">
            <thead>
                <tr>
                    <th scope="col" class="title-col">{{ $t('Settings') }}</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="userPermissionTitle in permissionTitles?.settings" :key="userPermissionTitle.id">
                    <td class="title-col">{{ userPermissionTitle.name }}</td>
                    <td>
                        <div class="form-check centered-checkbox">
                            <input class="form-check-input" v-model="selectedPermissions" type="checkbox" aria-label="Checkbox for View"
                                :value="`can_view_${snakeCase(userPermissionTitle.name)}`"
                                :disabled="!maxPermissions?.includes(`can_view_${snakeCase(userPermissionTitle.name)}`)">
                        </div>
                        </td>
                    <td>
                        <div class="form-check centered-checkbox">
                            <input class="form-check-input" v-model="selectedPermissions" type="checkbox" aria-label="Checkbox for Create"
                                :value="`can_create_${snakeCase(userPermissionTitle.name)}`"
                                :disabled="!maxPermissions?.includes(`can_create_${snakeCase(userPermissionTitle.name)}`)">
                        </div>
                        </td>
                    <td>
                        <div class="form-check centered-checkbox">
                            <input class="form-check-input" v-model="selectedPermissions" type="checkbox" aria-label="Checkbox for Update"
                                :value="`can_update_${snakeCase(userPermissionTitle.name)}`"
                                :disabled="!maxPermissions?.includes(`can_update_${snakeCase(userPermissionTitle.name)}`)">
                        </div>
                        </td>
                    <td>
                        <div class="form-check centered-checkbox">
                            <input class="form-check-input" v-model="selectedPermissions" type="checkbox" aria-label="Checkbox for Delete"
                                :value="`can_delete_${snakeCase(userPermissionTitle.name)}`"
                                :disabled="!maxPermissions?.includes(`can_delete_${snakeCase(userPermissionTitle.name)}`)">
                        </div>
                        </td>
                    <td>
                        <div class="form-check centered-checkbox">
                            <input class="form-check-input" v-model="selectedPermissions" type="checkbox" aria-label="Checkbox for Export"
                                :value="`can_export_${snakeCase(userPermissionTitle.name)}`"
                                :disabled="!maxPermissions?.includes(`can_export_${snakeCase(userPermissionTitle.name)}`)">
                        </div>
                        </td>
                </tr>
            </tbody>
        </table>

        <button @click="setPermissions" type="button" class="btn btn-primary" :disabled="disabled">{{ $t('Save') }}</button>
        <transition name="fade">
            <add-user-group-component v-if="showAddComponent" @closeModal="showAddComponent=false" />
        </transition>
    </div>
</template>

<script>
import {userGroupService} from '@/services/settings/userGroupService'
import _ from "lodash";
import AddUserGroupComponent from "@/components/settings/AddUserGroupComponent";

export default {
    name: "UserGroups",
    data() {
        return {
            selectedPermissions: [],


            showAddComponent: false,
        }
    },
    computed: {
        groupName() {
            return this.$route.params.group
        },
        groupId() {
            return this.$store.getters.getUserGroupId(this.groupName)
        },
        permissionTitles() {
            return this.$store.getters.permissionTitles
        },
        maxPermissions() {
            return this.$store.getters.maxPermissions
        }
    },
    watch: {
        groupId() {
            if (this.$route.name === 'UserGroups') {
                this.getPermissions()
            }
        }
    },
    created() {
        this.getPermissions()
    },
    methods: {
        snakeCase(string) {
            return _.snakeCase(string)
        },
        getPermissions() {
            if (this.groupId) {
                this.selectedPermissions = []
                userGroupService.getUserGroupPermissions(this.groupId).then(response => {
                    this.selectedPermissions = response.data.data
                })
            }
        },
        setPermissions() {
            this.disabled = true
            userGroupService.updateUserGroupPermissions(this.groupId, {permissions: this.selectedPermissions}).finally(() => this.disabled = false)
        },
        deleteUserGroup() {
            let areYouSure = confirm(`Delete ${this.groupName} ?`)

            if (areYouSure) {
                userGroupService.deleteUserGroup(this.groupId).then(response => {
                    this.$store.commit('deleteUserGroup', this.groupId)
                    this.$router.push({name: 'Settings'})
                })
            }
        },
    },
    components: {
        AddUserGroupComponent
    }
}
</script>

<style scoped>

</style>